import { Box } from '@revolut/ui-kit'
import { useGetKpiTemplateRulesApprovalsFlow } from '@src/api/kpiRules'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import React, { useEffect } from 'react'

export const KpiTemplateRulesApprovalFlow = ({
  id,
  approvalStatus,
}: {
  id: number
  approvalStatus: ApprovalStatuses
}) => {
  const { data, isLoading, refetch } = useGetKpiTemplateRulesApprovalsFlow(id)

  useEffect(() => {
    refetch()
  }, [approvalStatus])

  return (
    <Box pt="s-16">
      <ApprovalFlow steps={data || null} isLoading={isLoading} />
    </Box>
  )
}
